<script>
	import {folderContents} from '@/pages/admin/blogger/_components/Posts.svelte';
	import ImagePicker from './toolbar/helpers/ImagePicker.svelte';
	import {toast, SvelteToast} from '@zerodevx/svelte-toast';
	import {data} from '@/components/api/dataStore';
	import Toolbar from './toolbar/Toolbar.svelte';
	import {createEventDispatcher} from 'svelte';
	import {config} from '@/components/api/config';
	import {onMount, onDestroy} from 'svelte';
	import {request} from '@octokit/request';
	import {fly} from 'svelte/transition';
	import {Base64} from 'js-base64';
	import Debug from './Debug.svelte';
	import './EditorCSS.svelte';
	import JSONTree from 'svelte-json-tree';

	const {owner, repo, folder} = $config;

	const toastify = (
		message = 'error recieving error message',
		bad = true,
	) => {
		if (bad) {
			console.error(`%c ${message}`, 'color:lightred;background:black');
			toasts.push(message, {'--background': 'lightred'});
		} else {
			console.log(`%c ${message}`, 'color:lightgreen;background:black');
			toast.push(message, {theme: '--background: lightgreen'});
		}
	};

	async function loadData(newData) {
		try {
			if (newData) {
				delta = newData.contents;
				try {
					quill.setContents(delta);
					console.log('Successfully set quill contents');
				} catch (e) {
					toastify(`Error updating editor content 🤔: ${e}`, true);
				}
			} else {
				toastify('No contents found on data object 🤔', true);
				return;
			}
		} catch (e) {
			console.error(e);
		}
	}

	let fetchCount = 0;

	const unsubscribe = data.subscribe((newData) => {
		newData == $data;
		fetchCount >= 1 ? loadData(newData) : null;
		fetchCount++;
	});

	onDestroy(() => unsubscribe());

	$: showImagePicker = false;
	$: src =
		'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=100';
	let editor, quill, Quill;

	export let toolbarOptions = [
		[{header: [1, 2, false]}],
		[{font: ['quicksand', 'nunito', 'monospace']}],
		['blockquote', 'link', 'image', 'video'],
		['bold', 'italic', 'underline', 'strike'],
		[{list: 'ordered'}, {list: 'bullet'}],
		[{align: []}],
		['caption'],
		['clean'],
	];

	// Custom caption handler
	function captionHandler() {
		const range = quill.getSelection();
		const currentFormat = quill.getFormat(range);
		console.log(currentFormat);
		if (currentFormat['caption']) {
			quill.removeFormat(range.index, range.index + range.length);
		} else {
			quill.formatLine(
				range.index,
				range.index + range.length,
				'caption',
				true,
			);
		}
	}

	// Pass handling to ImagePicker.svelte
	function imageHandler() {
		showImagePicker = true;
	}

	function handleInsertImage(e) {
		quill.focus();
		const range = quill.getSelection();
		const value = e.detail.src;
		quill.insertEmbed(
			quill.getSelection().index,
			'image',
			value,
			Quill.sources.USER,
		);
		showImagePicker = false;
	}

	onMount(async () => {
		// Import quill library
		const module = await import('quill');
		Quill = module.default;
		console.dir(Quill);

		// Custom caption module
		const Block = Quill.import('blots/block');
		class Caption extends Block {
			static formats(domNode) {
				return this.tagName.indexOf(domNode.tagName) + 1;
			}
		}
		Caption.blotName = 'caption';
		Caption.tagname = 'CAPTION';
		Caption.className = 'caption';
		Quill.register({
			'formats/caption': Caption,
		});

		// Register custom font.
		let Font = Quill.import('formats/font');
		Font.whitelist = ['nunito', 'quicksand', 'monospace'];
		Quill.register(Font, true);

		// Initialize editor
		quill = new Quill(editor, {
			modules: {
				toolbar: {
					container: '#toolbar',
					modules: toolbarOptions,
					handlers: {
						image: imageHandler,
						caption: captionHandler,
					},
				},
			},
			theme: 'snow',
			placeholder: "What's the word...",
		});

		// quill.formatText(6, 4, 'caption', true);
		// let captionNode = document.querySelector('#container em');
		// let captionBlot = Quill.find(captionNode);
		// console.log(captionBlot);

		quill.on('text-change', function (delta, source) {
			if (showHTML) updateHtmlOutput();
			if (showDelta) updateDelta();
		});
		if (showHTML) updateHtmlOutput();
	});

	// Return the HTML content of the editor
	function getQuillHtml() {
		return quill.root.innerHTML;
	}
	async function updateHtmlOutput() {
		if (!showHTML) showHTML = true;
		try {
			outputHTML.innerText = getQuillHtml();
		} catch (err) {
			console.error('Error setting outputHTML.innerText', err);
		}
	}
	function updateDelta() {
		delta = quill.getContents();
	}
	function toggleDelta() {
		showDelta = !showDelta;
		updateDelta();
	}

	let delta = {},
		outputHTML,
		showDelta = false,
		showHTML = false,
		debug = true;
	$: value = {delta};

	function toggleHTML() {
		showHTML = !showHTML;
		console.log('%c Toggled HTML.  showHTML = ', `color:blue`, showHTML);
		if (showHTML) {
			setTimeout(() => {
				updateHtmlOutput();
			}, 100);
		}
	}
	let newPostName = '';
	let newFilePath = 'test/';
	// TODO: REMOVE EXPOSED TOKEN
	async function saveFile() {
		updateDelta().then(async () => {
			// console.log(delta);
			const content = Base64.encode(JSON.stringify(delta));
			// console.log(content);
			const res = async () =>
				await request('PUT /repos/{owner}/{repo}/contents/{path}', {
					headers: {
						authorization: `token d425854a960116d78167ec6bb4cf32752293b00c`,
					},
					owner: owner,
					repo: repo,
					path: `${newFilePath}${newPostName}.json`,
					message: 'Saving new post',
					content: content,
				});
			try {
				await res();
				toast.push('Successfully saved file to Github!', {
					theme: {'--toastBackground': 'rgba(66,200,130,0.9)'},
				});
				console.log('Successfully saved file to Github.  Res = ', res);
				// console.log('Content = ', content);
			} catch (e) {
				console.error('Error saving file: ', e);
			}
		});
	}
</script>

<SvelteToast />

<ImagePicker bind:showImagePicker bind:src on:message={handleInsertImage} />

<div id="container" class="container">
	<Toolbar />
	<div bind:this={editor} />
</div>
{#if debug}
	<debug>
		<!-- {#if showDelta} -->
		<!-- <pre>{JSON.stringify(delta, null, 2)}</pre> -->
		<container>
			<!-- {#if delta} -->
			<!-- {#key delta} -->
			<JSONTree {value} />
			<!-- {/key} -->
			<!-- {/if} -->
		</container>
		<!-- {/if} -->
		<debugButtons>
			<form class="saveFile">
				<input required placeholder="post1" bind:value={newPostName} />
				<button type="submit" class="saveButton" on:submit={saveFile}>
					Save new File
				</button>
			</form>
			<br />

			<button class="toggleDelta" on:click={toggleDelta}>
				Show Delta
			</button>
			<br />

			<button class="toggleHTML" on:click={toggleHTML}>Show HTML</button>
			<br />
		</debugButtons>
		{#if showHTML}
			<div
				id="output-html"
				transition:fly={{
					y: 20,
				}}
			>
				<div id="output-html" bind:this={outputHTML} />
			</div>
		{/if}
	</debug>
{/if}

<style>debugButtons button{margin:auto;bottom:5rem;z-index:50}debug{display:flex;justify-content:space-around}debugButtons{display:flex;position:fixed;bottom:1rem;width:50%}.saveFile,button{display:flex;--tw-shadow:0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow)}button{border-radius:.25rem;font-family:Nunito,sans-serif;margin:auto;padding:.25rem .75rem;background:var(--bg)}#output-html{display:block;margin:2.5rem auto;position:relative;overflow-wrap:break-word;max-width:50vw;width:50vw;z-index:-1}input{padding:0 1rem}container,container:first-child{--tw-bg-opacity:1!important;background-color:rgba(31,41,55,var(--tw-bg-opacity))!important;border-radius:.375rem!important;max-width:100vw!important;padding:2.5rem!important;--tw-shadow:0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)!important;box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow)!important;--tw-text-opacity:1!important;color:rgba(156,163,175,var(--tw-text-opacity))!important;width:50%!important}</style>
