<script>
	import Getcss from '../../../components/gardener/Getcss.svelte';
</script>

<Getcss />

<page class="flex w-full h-full">
	<container
		style="width: 600px; height: 1000px"
		class="m-auto border-2 border-gray-300"
	>
		<div
			id="wrapper"
			style="display: inline-flex; flex-direction: column; align-items: flex-end; justify-content: flex-start;"
		>
			<p
				style="width: 101px; height: 14px; font-size: 15px; font-weight: 700; letter-spacing: 3px; text-align: right; text: rgba(40, 35, 35, 1);"
			>
				EDITOR / <br />
			</p>
			<div style="height: 9px;" />
			<p
				style="width: 119px; height: 14px; font-size: 15px; text-align: right; text: rgba(40, 35, 35, 1);"
			>
				Jordan Bushey
				<br />
				<br />
			</p>
			<div style="height: 9px" />
			<p
				style="width: 300px; height: 71px; font-size: 15px; text-align: right; text: rgba(142, 142, 142, 1);"
			>
				Everything you need to stay up to date on the changing landscape
				of digital strategy, including weekly insights, tips and tricks
				and innovations throughout the industry.
			</p>
		</div>
		<div
			style="left: 20px; width: 566px; top: 50px; height: 93px; position: relative;"
		>
			<p
				style="width: 81px; height: 48px; left: 0px; top: 0px; position: absolute; font-size: 72px; font-weight: 700; text-align: right; color: #65c9cb; text: #65c9cb;"
			>01</p>
			<p
				style="width: 72px; height: 11px; left: 8px; top: 73px; position: absolute; font-size: 13px; text-align: right; text: rgba(40, 35, 35, 1);"
			>STRATEGY</p>
			<div
				style="width: 5px; margin-left: 85px; height: 100px; background: linear-gradient(180deg, #66CACC 0%, #4A8A8E 100%);"
			/>
			<p
				style="width: 464px; height: 83px; left: 102px; top: 10px; position: absolute; font-size: 14px; letter-spacing: 0px; line-height: 18px; text: rgba(40, 35, 35, 1);"
			>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Congue
				vitae purus pulvinar integer ipsum sed semper commodo morbi. Sit
				eget aliquet cursus ultricies metus, ornare habitant ultricies.
				Sed sagittis lobortis massa est. Aliquet at in sed sodales
				ligula odio phasellus.
			</p>
		</div>
	</container>
</page>

<style>#wrapper{border:grey;padding:2rem}</style>
