<script>
	import { fly } from 'svelte/transition';
</script>

<svg width="60" height="50" viewBox="0 0 100 100">
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100"
		height="100"
		fill="none"
		viewBox="0 0 65 59">
		<path
			in:fly={{ y: 10, delay: 1250, duration: 750 }}
			id="path"
			fill="var(--text)"
			d="M32.866 17.99a1.474 1.474 0 100-2.95v2.95zm21.133 12.713a1.474 1.474 0 00-2.949 0h2.949zM9.477 17.677l-.99-1.093.99 1.093zm17.755 18.05l1.051-1.035-1.05 1.034zm6.354-.05l1.067 1.018-1.067-1.018zm7.468-5.693a1.474 1.474 0 10-2.134-2.035l2.134 2.035zM13.22 17.989h19.646v-2.948H13.22v2.948zm37.83 12.714v15.096h2.949V30.703H51.05zm-3.613 18.824H13.22v2.949h34.217v-2.95zm-37.83-3.728V21.718H6.66v24.08h2.949zm3.613 3.728c-1.332 0-2.183-.391-2.705-.93-.53-.548-.907-1.44-.907-2.798H6.659c0 1.898.537 3.607 1.737 4.848 1.207 1.247 2.9 1.829 4.824 1.829v-2.95zm37.83-3.728c0 1.358-.378 2.25-.907 2.797-.523.54-1.373.93-2.706.93v2.95c1.925 0 3.618-.582 4.825-1.83 1.2-1.24 1.737-2.95 1.737-4.847H51.05zM13.22 15.04c-1.664 0-3.285.231-4.733 1.543l1.98 2.185c.645-.584 1.37-.78 2.753-.78v-2.948zm-4.733 1.543c-1.687 1.53-1.828 3.333-1.828 5.134h2.949c0-1.666.165-2.32.86-2.949l-1.981-2.184zm-.06 2.127L26.18 36.76l2.102-2.069-17.755-18.049-2.102 2.068zm26.226 17.984l6.4-6.711-2.133-2.035-6.4 6.71 2.133 2.036zm-8.472.065a5.897 5.897 0 008.472-.065l-2.134-2.035a2.949 2.949 0 01-4.236.032l-2.102 2.068z"
		/>
		<g
			filter="url(#mailer-filter1)"
			in:fly={{ y: 10, delay: 1500, duration: 750 }}>
			<path
				fill="#3CC"
				d="M48.912 10.694a1.474 1.474 0 10-2.949 0h2.949zm-2.949 11.73a1.474 1.474 0 102.949 0h-2.949zm-3.602-7.437a1.474 1.474 0 000 2.949v-2.95zm10.148 3.002a1.474 1.474 0 10.031-2.948l-.031 2.948zm-6.546-7.295v5.767h2.949v-5.767h-2.949zm0 5.767v5.963h2.949v-5.963h-2.949zm1.474-1.474h-5.076v2.949h5.076v-2.95zm-.015 2.949l5.087.053.031-2.948-5.087-.054-.031 2.949z"
			/>
		</g>
		<defs>
			<filter
				id="mailer-filter1"
				width="27.856"
				height="29.422"
				x="33.514"
				y="1.848"
				color-interpolation-filters="sRGB"
				filterUnits="userSpaceOnUse">
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation="3.686" />
				<feColorMatrix
					values="0 0 0 0 0.2 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.49 0"
				/>
				<feBlend in2="BackgroundImageFix" result="mailer_dropShadow" />
				<feBlend in="SourceGraphic" in2="mailer_dropShadow" result="shape" />
			</filter>
		</defs>
	</svg>
</svg>

<style>svg{margin-top:3px}</style>
