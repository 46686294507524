<script>
	import BloggerIcon from '@/components/ui/icons/BloggerIcon.svelte';
	import MailerIcon from '@/components/ui/icons/MailerIcon.svelte';
	import {quintOut} from 'svelte/easing';
	import {fly} from 'svelte/transition';
	import {url} from '@roxi/routify';
</script>

<group class="relative flex flex-col transform scale-150 top-20">
	<a href="/admin/blogger" use:$url class="link">
		<BloggerIcon />

		<p in:fly={{y: 5, duration: 750, delay: 850, easing: quintOut}}>
			Blogger
		</p>
	</a>
	<a href="/admin/mailer" use:$url class="link">
		<MailerIcon />

		<p in:fly={{y: 5, duration: 750, delay: 1500, easing: quintOut}}>
			Mailer
		</p>
	</a>
</group>

<style>group{font-family:Nunito,sans-serif;height:12rem;margin-left:auto;width:-webkit-max-content;width:-moz-max-content;width:max-content}group,group a{display:flex;margin-right:auto}group a{border-radius:.75rem;height:4rem;font-size:3rem;line-height:1;margin-top:auto;margin-bottom:auto;padding-left:.5rem;padding-right:.5rem;padding-bottom:.25rem;right:.5rem;text-align:center;width:12rem}group p{display:flex;height:2rem;font-size:2.25rem;line-height:2.5rem;margin:auto;text-align:left;width:100%}.link{border:1px solid hsla(0,0%,100%,0);transition:border .3s}.link:hover{border:1px solid var(--text)}p{height:50px;margin:auto}</style>
