<script>
	import {setTheme, themeStore} from '../css/themes/themeStore';
	import FadeDecorator from '@/components/FadeDecorator.svelte';
	import SvelteToast from '@/components/ui/toast/SvelteToast.svelte';
	import {fly, fade} from 'svelte/transition';
	import {onMount} from 'svelte';

	// Toast
	const options = {
		duration: 5000, // duration of progress bar tween
		dismissable: true, // allow dismiss with close button
		initial: 1, // initial progress bar value
		progress: 0, // current progress
		reversed: false, // insert new toast to bottom of stack
		intro: {x: 256}, // toast intro fly animation settings
		theme: {}, // css var overrides
	};

	let theme = 'theme' in localStorage ? localStorage.theme : 'light';

	onMount(() => setTheme(theme));
</script>

<SvelteToast {options} />

<div class="theme-toggle">
	{#key $themeStore}
		{#if $themeStore === 'light'}
			<button
				in:fly={{y: -50, duration: 750, delay: 250}}
				out:fly={{y: -50, duration: 750}}
				on:click={() => setTheme('dark')}
			>
				☀️
			</button>
		{:else if $themeStore === 'dark'}
			<button
				in:fly={{y: -50, duration: 750, delay: 250}}
				out:fly={{y: -50, duration: 750}}
				on:click={() => setTheme('light')}
			>
				🌙
			</button>
		{/if}
	{/key}
</div>

<div class="app">
	<slot decorator={FadeDecorator} />
</div>

<style>:global(html){transition:background-color .15s;--bg:#fff;--bg-rgba:255,255,255;--text:#262728;--text-rgba:38,39,40;margin:0;padding:0;background-color:var(--bg);color:var(--text)}:global(html.dark){--bg:#262728;--bg-rgba:38,39,40;--text:#fff;--text-rgba:255,255,255}.theme-toggle{font-size:1.25rem;line-height:1.75rem;position:absolute;right:6rem;z-index:50;transition:filter .5s;filter:saturate(0);top:14px}.theme-toggle button{position:absolute;outline:none;right:5px}.theme-toggle:hover{filter:saturate(1) brightness(1)}</style>
