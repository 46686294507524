<script>
	import {onMount} from 'svelte';

	import Debugger from '@/components/utils/Debugger.svelte';
	import Thumbnail from '@/components/ui/Thumbnail.svelte';
	// import Nodes from './Nodes.svelte';
	import H1 from './elements/H1.svelte';
	import P from './elements/P.svelte';

	$: editor = null;
	$: elements = Array(2);
	$: output = editor ? editor.innerHTML : 'No Output Found';

	let text;
	const options = [
		{component: H1, name: 'h1', text: new String('header')},
		{component: P, name: 'p', text: new String('paragraph')},
	];

	let selected = options[0];

	function createNode(el) {
		elements = [...elements, el];
		output = editor.innerHTML;
	}

	function showOutput() {
		console.log(output);
	}
	$: event = {};
	function handleKeypress(e) {
		output = editor.innerHTML;
		event = e;
		console.log('e = ', e);
	}
	onMount(() => {
		elements = [H1, P];
		handleKeypress({message: 'mounted'});
	});
</script>

<Thumbnail />

<buffer class="relative block w-screen h-48" />

{#key event}{JSON.stringify(Object.entries(event))}{/key}

<!-- <div class="editor" bind:this={editor}> -->
<!-- <Nodes {elements} let:text let:el> -->
<!-- {text} -->
<!-- {#each elements as el} -->
<!-- <svelte:component this={el} {text} /> -->
<!-- {/each} -->
<!-- </Nodes> -->
<!-- </div> -->

<div class="editor" bind:this={editor} on:keypress={(e) => handleKeypress(e)}>
	{#each elements as el}
		<svelte:component this={el} {text} />
	{/each}
</div>

<controls>
	<select bind:value={selected}>
		{#each options as option}
			<option value={option}>{option.name}</option>
		{/each}
	</select>
	<button on:click={createNode(selected.component)}>New Node</button>
	<button on:click={showOutput}>New Node</button>
	{output}
</controls>

{#key elements}
	<label for="output">
		<pre name="output">{output}</pre>
	</label>
{/key}

<Debugger>
	<div>
		Options: <pre>{JSON.stringify(options, null, 2)}</pre>
	</div>
	<div>
		Seleceted: <pre>{JSON.stringify(selected, null, 2)}</pre>
	</div>
	<div>
		Elements: <pre>{JSON.stringify(elements, null, 2)}</pre>
	</div>
	<div>
		{#if editor}Editor: <pre>{Object.values(editor)}</pre>{/if}
	</div>
</Debugger>

<style>button,select{--tw-border-opacity:1;border-color:rgba(209,213,219,var(--tw-border-opacity));border-radius:.25rem;border-width:1px;display:flex;margin:auto;padding:.5rem 1rem;width:-webkit-max-content;width:-moz-max-content;width:max-content}controls{display:block;flex-direction:column;margin-top:2.5rem}:global(body){padding:0}:global(.editor h1){font-size:1.875rem;line-height:2.25rem;text-align:center}.editor{margin:auto;width:18rem}pre{display:flex;max-width:100vw;white-space:pre-wrap}</style>
