<script>
	import {clickOutside} from '@/components/utils/clickOutside';
	import {createEventDispatcher} from 'svelte';
	import {fly} from 'svelte/transition';

	const dispatch = createEventDispatcher();

	export let showImagePicker = false;
	export let src = '';

	function handleInsertImage() {
		showImagePicker = false;
		dispatch('message', {
			src: src,
		});
	}

	function handleKeydown(e) {
		if (e.key === 'Enter') handleInsertImage();
	}

	function handleClickOutside() {
		showImagePicker = false;
		console.log('%c ImagePicker:  Enabling ready', 'color:orange');
		console.log('Count = ', count);
		console.log('showImagePicker = ', showImagePicker);
	}
</script>

{#if showImagePicker === true}
	{#key showImagePicker}
		<div in:fly={{y: -10}} out:fly={{y: 10}} class="image-picker">
			<input
				class="img-input"
				type="text"
				placeholder="Insert img url"
				bind:value={src}
				style="flex: 1"
				on:keydown={(e) =>
					e.key === 'Enter' ? handleInsertImage() : null}
			/>
			<div class="flex m-auto img-buttons w-max">
				<button
					class="transition-colors submit-btn"
					on:click={() => {
						console.log('sumbitting');
						handleInsertImage();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25"
						height="25"
						fill="none"
						viewBox="0 0 50 50"
					>
						<circle
							cx="25"
							cy="25"
							r="24.5"
							stroke="var(--bg)"
							opacity=".5"
						/>
						<path
							fill="var(--text)"
							d="M14.134 26.821a1 1 0 00-1.138 1.645l1.138-1.645zm20.725-13.747a1 1 0 00-1.841-.78l1.841.78zm-11.454 21.38l.569-.823-.57.822zm1.49-.433l-.921-.39.92.39zm-11.9-5.555l9.84 6.81 1.139-1.645-9.84-6.81-1.138 1.645zm12.82 5.945l9.044-21.337-1.841-.78-9.044 21.337 1.841.78zm-2.98.864a2 2 0 002.98-.864l-1.841-.78-1.138 1.644z"
						/>
					</svg>
				</button>
			</div>
		</div>
	{/key}
{/if}

<style>.submit-btn{border-radius:9999px;margin:.25rem;outline:2px solid transparent;outline-offset:2px;transition:box-shadow .2s}.submit-btn:hover{fill:#fff!important;color:#fff!important}.img-input:focus,.submit-btn:hover{box-shadow:0 0 12px rgba(52,157,255,.4)}.img-input:focus{border:1px solid rgba(52,200,255,.8)}.image-picker{border-radius:.5rem;margin-left:auto;margin-right:auto;padding:.5rem;position:absolute;right:0;left:0;--tw-shadow:0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);width:50%;z-index:20;font-family:Quicksand}.image-picker,.img-input{display:flex;background-color:var(--bg);color:var(--text)}.img-input{--tw-border-opacity:1;border-color:rgba(147,197,253,var(--tw-border-opacity));border-radius:.25rem;border-width:1px;font-size:.75rem;line-height:1rem;margin:auto;outline:2px solid transparent;outline-offset:2px;padding:.25rem;text-align:center;width:100%;box-shadow:0 0 12px rgba(52,157,255,0);max-width:90vw}.img-input::-moz-placeholder{text-align:center}.img-input:-ms-input-placeholder{text-align:center}.img-input::placeholder{text-align:center}</style>
