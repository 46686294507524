<script>
	import Thumbnail from '@/components/ui/Thumbnail.svelte';
	import Editor from '@/components/editor/Editor.svelte';
	import {initClient} from '@urql/svelte';
	import {fade} from 'svelte/transition';
	import {layout} from '@roxi/routify';
	import {onMount} from 'svelte';
	import './_components/COCSS.svelte';
	import Posts from './_components/Posts.svelte';

	// Initialize / Authorize GraphQL Client
	const client = initClient({
		url: 'https://api.github.com/graphql',
		fetchOptions: () => {
			// TODO: UNCOMMENT ⬇
			// const token = $user['https://hasura.io/jwt/claimsgh_token'].token;
			// TODO: DELETE TOKEN
			const token = 'd425854a960116d78167ec6bb4cf32752293b00c';
			return {
				headers: {
					authorization: token ? `Bearer ${token}` : '',
				},
			};
		},
	});

	const seed = (max) => Math.floor(Math.random() * Math.floor(max));
	const picsum = `https://picsum.photos/seed/${seed(700)}/1920/1080?blur=8`;
	let mounted = false;
	onMount(() => {
		mounted = true;
	});

	const posts = $layout.parent.parent.children[1].children[0].children;
</script>

{#if mounted}
	<transition in:fade={{delay: 1000, duration: 3000}}>
		<background style="background-image: url({picsum});" />
	</transition>
{/if}

<Thumbnail />

<Posts />

<Editor />

<style>transition{z-index:-1}background{opacity:.1;position:fixed;width:110vw;height:110vh;top:-5vh;left:-5vw;background-size:cover;background-repeat:0;z-index:-1;filter:blur(20px)}</style>
