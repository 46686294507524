<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
let data;
let calling = false;
// let data: Object;
function getCSS() {
    return __awaiter(this, void 0, void 0, function* () {
        calling = true;
        const res = yield fetch('http://localhost:8080/getcss');
        return res.json();
        // const extractCSS = new RegExp();
    });
}
</script>

<button on:click={() => getCSS()}>Get CSS</button>

{#if calling}
	{#await getCSS()}
		<p>...waiting</p>
	{:then data}
		<!-- @ts-ignore -->
		<pre>{data.message}</pre>
	{:catch error}
		<p>An error occurred!</p>
	{/await}
{/if}

<style>button,pre{display:flex;margin:2.5rem auto;width:-webkit-max-content;width:-moz-max-content;width:max-content}button{--tw-border-opacity:1;border-color:rgba(209,213,219,var(--tw-border-opacity));border-radius:.25rem;border-width:1px;font-family:Quicksand,sans-serif;padding:.5rem 1rem}</style>
