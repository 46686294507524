<script>
	import {initClient, operationStore, query} from '@urql/svelte';
	import {readFolder, readFile} from '@/components/api/github';
	import {activeFileName, config} from '@/components/api/config';
	import {createEventDispatcher, onMount} from 'svelte';
	import Sidebar from '@/components/ui/Sidebar.svelte';
	import {formatTitle, getFileType} from './utils';
	import {user} from '@/components/auth/authStore';
	import {data} from '@/components/api/dataStore';
	import {writable} from 'svelte/store';

	const {owner, repo, folder} = $config;

	export const folderContents = operationStore(
		`
		query ReadFolder($owner: String!, $repo: String!, $folder: String!) {
			repository(owner: $owner, name: $repo) {
				object(expression: $folder) {
				... on Tree {
					entries {
					name
					type
					mode
					object {
						... on Blob {
						byteSize
						text
						isBinary
						}
					}
					}
				}
				}
			}
		}`,
		{owner, repo, folder},
	);
	query(folderContents);

	async function handleLoadData(newData) {
		const newDataObj = JSON.parse(newData);
		data.set(newDataObj);
	}
</script>

<Sidebar title="Blog">
	{#if $folderContents.fetching}
		<p>Loading...</p>
	{:else if $folderContents.error}
		<p>Oh no... {$folderContents.error.message}</p>
	{:else}
		{#each $folderContents.data.repository.object.entries as post}
			<post on:click={() => handleLoadData(post.object.text)}>
				<postName>{formatTitle(post.name)}</postName>

				<fileType>{getFileType(post.name)}</fileType>
			</post>
		{/each}
	{/if}
</Sidebar>

<style>fileType{height:0;font-size:.875rem;line-height:1.25rem;margin-left:auto;opacity:.5}postName{font-family:Quicksand,sans-serif;font-size:1rem;line-height:1.5rem;margin-top:1rem;margin-bottom:1rem;text-align:left;background:var(--bg)}post{border-radius:.375rem;box-sizing:content-box;cursor:pointer;display:flex;flex-direction:row;padding:.25rem .75rem;border:1px solid rgba(117,179,255,0)}post:hover{border:1px solid #75b3ff}</style>
