<script>
	import {fade, fly} from 'svelte/transition';
	import ToastItem from './ToastItem.svelte';
	import {flip} from 'svelte/animate';
	import {toast} from './stores.js';
	export let options = {};
	const defaults = {
		duration: 4000,
		dismissable: true,
		initial: 1,
		progress: 0,
		reversed: false,
		intro: {x: 256},
		theme: {},
	};
	toast._opts(defaults);
	$: toast._opts(options);
	const getCss = (theme) =>
		Object.keys(theme).reduce((a, c) => `${a}${c}:${theme[c]};`, '');
</script>

<ul>
	{#each $toast as item (item.id)}
		<li
			in:fly={item.intro}
			out:fade
			animate:flip={{duration: 200}}
			style={getCss(item.theme)}
		>
			<ToastItem {item} />
		</li>
	{/each}
</ul>

<style>ul{position:fixed;margin:0;padding:0;list-style-type:none;z-index:9999;pointer-events:none;top:var(--toastContainerTop,1.5rem);right:var(--toastContainerRight,2rem);bottom:var(--toastContainerBottom,auto);left:var(--toastContainerLeft,auto)}</style>
