<script>
	import { fly } from 'svelte/transition';
</script>

<svg
	class="pt-2 ml-1 mr-1"
	xmlns="http://www.w3.org/2000/svg"
	width="48"
	height="58"
	fill="none"
	viewBox="0 0 48 58">
	<g
		filter="url(#blogger-filter1)"
		in:fly={{ y: 10, delay: 500, duration: 750 }}>
		<rect
			width="28"
			height="39"
			x="8"
			y="1.3"
			stroke="var(--text)"
			stroke-width="2.112"
			rx="3.873"
			id="path"
		/>
	</g>
	<g
		filter="url(#blogger-filter2)"
		in:fly={{ y: 10, delay: 500, duration: 750 }}>
		<path
			id="path"
			stroke="var(--text)"
			stroke-width="2.112"
			d="M35.272 8.568v0a4.79 4.79 0 014.79 4.79v26.064a4.929 4.929 0 01-4.93 4.93H18.455a4.401 4.401 0 01-4.401-4.402v0"
		/>
	</g>
	<g filter="url(#lines-filter1)">
		<path
			in:fly={{ x: -6, delay: 950, duration: 750 }}
			stroke="#3CC"
			stroke-linecap="round"
			stroke-width="2.112"
			d="M14.8 20.91h13.2"
		/>
	</g>
	<g filter="url(#lines-filter1)">
		<path
			in:fly={{ x: -5, delay: 750, duration: 750 }}
			stroke="#3CC"
			stroke-linecap="round"
			stroke-width="2.112"
			d="M14.8 14.767h11.3"
		/>
	</g>
	<g filter="url(#lines-filter1)">
		<path
			in:fly={{ x: -3, delay: 1150, duration: 750 }}
			stroke="#3CC"
			stroke-linecap="round"
			stroke-width="2.112"
			d="M14.8 26.9h14"
		/>
	</g>
	<defs>
		<filter
			id="lines-filter1"
			width="34.122"
			height="20.959"
			x="9.385"
			y="10.431"
			color-interpolation-filters="sRGB"
			filterUnits="userSpaceOnUse">
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feColorMatrix
				in="SourceAlpha"
				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
			/>
			<feOffset />
			<feGaussianBlur stdDeviation="2.212" />
			<feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.4 0" />
			<feBlend in2="BackgroundImageFix" result="lines-dropShadow" />
			<feBlend in="SourceGraphic" in2="lines-dropShadow" result="shape" />
		</filter>
		<filter
			id="blogger-filter1"
			width="41.814"
			height="53.097"
			x=".978"
			y=".197"
			color-interpolation-filters="sRGB"
			filterUnits="userSpaceOnUse">
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feColorMatrix
				in="SourceAlpha"
				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
			/>
			<feOffset dy="5.897" />
			<feGaussianBlur stdDeviation="2.949" />
			<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
			<feBlend in2="BackgroundImageFix" result="blogger_dropShadow" />
			<feBlend in="SourceGraphic" in2="blogger_dropShadow" result="shape" />
		</filter>
		<filter
			id="blogger-filter2"
			width="39.915"
			height="49.69"
			x="7.1"
			y="7.512"
			color-interpolation-filters="sRGB"
			filterUnits="userSpaceOnUse">
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feColorMatrix
				in="SourceAlpha"
				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
			/>
			<feOffset dy="5.897" />
			<feGaussianBlur stdDeviation="2.949" />
			<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
			<feBlend in2="BackgroundImageFix" result="blogger_dropShadow" />
			<feBlend in="SourceGraphic" in2="blogger_dropShadow" result="shape" />
		</filter>
		<filter
			id="blogger-filter3"
			width="25"
			height="23.119"
			x="9.385"
			y="9.288"
			color-interpolation-filters="sRGB"
			filterUnits="userSpaceOnUse">
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feColorMatrix
				in="SourceAlpha"
				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
			/>
			<feOffset />
			<feGaussianBlur stdDeviation="2.212" />
			<feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.4 0" />
			<feBlend in2="BackgroundImageFix" result="blogger_dropShadow" />
			<feBlend in="SourceGraphic" in2="blogger_dropShadow" result="shape" />
		</filter>
	</defs>
</svg>

<style>svg{margin-top:3px}</style>
