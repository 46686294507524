<script>
	import { writable } from 'svelte/store';
	import { tweened, spring } from 'svelte/motion';
	import { cubicInOut, quintOut } from 'svelte/easing';
	import { freq, size, scale } from './svgFilterStore';

	export let text = 'feTurbulence';
	let controls = false;
</script>

<svg height="0" width="0">
	<filter id="wave">
		<feTurbulence type="turbulence" baseFrequency={$freq} numOctaves="2" result="turbulence" />
		<feDisplacementMap
			in2="turbulence"
			in="SourceGraphic"
			scale={$size}
			xChannelSelector="R"
			yChannelSelector="G"
		/>
	</filter>
</svg>
<div id="filter" style="filter: url(#wave); transform: scale({$scale})">
	<slot name="text">{text}</slot>
</div>
{#if controls}
	<hr />
	Scale: {$size}<br />
	<input id="size" type="range" bind:value={$size} min="0" max="100" /><br />
	baseFrequency: {$freq}<br />
	<input id="freq" type="range" bind:value={$freq} min="0" max="1" step="0.001" />
{/if}

<style>div{font-size:21px;color:var(--text)}input{position:absolute}#size{top:250px;left:0}#freq{left:0;top:150px}</style>
