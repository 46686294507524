<script>
	import {writable} from 'svelte/store';
	import {resizable} from './resizable';
	export let title = 'Sidebar Title';

	const initial_width =
		'sidebarWidth' in localStorage
			? Number(localStorage.sidebarWidth)
			: 600;
	const width = writable(initial_width);
	const minWidth = 400;
	let sidebarWidth;
	function handleDragStart() {}

	function handleDragMove(mouse) {
		width.update(($width) => $width + mouse.detail.dx);
	}

	function handleDragEnd(event) {
		if ($width < minWidth) width.set(minWidth);
		if ($width > sidebarWidth) width.set(sidebarWidth);
		localStorage.setItem('sidebarWidth', $width);
	}

	$: window_width = 500;
	$: percentage = ($width / window_width) * 100;

	let hovering = true;
	let hoverTimer = null;
	function handleMouseOver() {
		clearTimeout(hoverTimer);
		hovering = true;
	}
	function handleMouseOut() {
		clearTimeout(hoverTimer);
		hoverTimer = setTimeout(() => {
			hovering = false;
		}, 1000);
	}
</script>

<svelte:window bind:innerWidth={window_width} />

<mouseTrap on:mouseover={handleMouseOver} />

<sidebarContainer
	style="width:{$width}px; min-width:{minWidth}px"
	class:collapsed={!hovering}
>
	<sidebar
		bind:clientWidth={sidebarWidth}
		on:mouseout={handleMouseOut}
		on:mouseover={handleMouseOver}
		id="sidebar"
	>
		<slot name="title"><h2>{title}</h2></slot>
		<slot />
		<div
			use:resizable
			id="dragbar"
			on:dragstart={handleDragStart}
			on:dragmove={handleDragMove}
			on:dragend={handleDragEnd}
		/>
	</sidebar>
</sidebarContainer>

<style>h2{font-family:Quicksand,sans-serif;font-size:1.5rem;line-height:2rem;margin:0 auto 1rem}mouseTrap{height:75%;position:fixed;left:0;top:9rem;width:8rem;z-index:50}sidebarContainer{transition:transform .5s ease-in-out;position:fixed;left:0;top:5rem;z-index:40}.collapsed{transform:translateX(-100%)}sidebar{transition:transform .5s ease-in-out;display:flex;flex-direction:column;margin-top:2rem;margin-right:auto;overflow:auto;padding:1.5rem 2rem;border-radius:7px;border-top-left-radius:0;border-bottom-left-radius:0;background:var(--bg);background-image:linear-gradient(180deg,hsla(0,0%,100%,0) 99.5%,rgba(51,68,85,.06666666666666667)),linear-gradient(180deg,hsla(0,0%,100%,0) 98%,rgba(51,68,85,.06666666666666667)),linear-gradient(180deg,hsla(0,0%,100%,0) 98%,var(--bg)),linear-gradient(180deg,hsla(0,0%,100%,0) 98%,var(--bg));height:80vh;box-shadow:5px 5px 20px rgba(34,34,34,.13333333333333333);position:relative;overflow:auto}#dragbar{width:15px;right:-3px;top:0;bottom:0;background-image:linear-gradient(90deg,hsla(0,0%,100%,0),rgba(51,68,85,.06666666666666667)),linear-gradient(90deg,hsla(0,0%,100%,0) 80%,rgba(51,68,85,.13333333333333333)),linear-gradient(0deg,hsla(0,0%,100%,0) 90%,var(--bg) 95%,hsla(0,0%,100%,0)),linear-gradient(180deg,hsla(0,0%,100%,0) 90%,var(--bg) 95%,hsla(0,0%,100%,0));cursor:col-resize;position:absolute;height:100%;z-index:5}</style>
