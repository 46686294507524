<script>
	import {firebaseConfig, handleSignIn, handleSignOut} from './firebaseUtils';
	import {freq, size, scale} from '@/components/ui/svgFilterStore';
	import FadeDecorator from '@/components/FadeDecorator.svelte';
	import SvgFilter from '@/components/ui/SVGFilter.svelte';
	import {FirebaseApp, User} from 'sveltefire';
	import {url} from '@roxi/routify';
	import firebase from 'firebase/app';
	import 'firebase/firestore';
	import 'firebase/auth';

	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	}
	const provider = new firebase.auth.GoogleAuthProvider();
	firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

	const morph = () => {
		freq.set(0.05);
		size.set(35);
		scale.set(2);
	};
	const unmorph = () => {
		freq.set(0);
		size.set(0);
		scale.set(1);
	};
	const randomAvatar = 'https://i.pravatar.cc/50';
	const avatar = randomAvatar;
</script>

<FirebaseApp {firebase}>
	<!-- Nav Bar -->
	<nav class="flex h-16 text-center text-gray-600 dark:text-white navbar">
		<User let:user let:auth>
			<!-- TODO: Expose `user?.picture` as an option -->
			<img
				class="absolute w-10 rounded-full top-2 left-3 avatar"
				src={avatar}
				alt="avatar"
			/>
			<div class="flex user-details">
				<span
					class="absolute text-xl font-black uppercase dark:text-white left-14 top-4"
				>
					&nbsp;&nbsp;{user?.displayName}
				</span>
			</div>
		</User>
		<signOut
			class="absolute top-0 flex float-right m-4 font-bold cursor-pointer right-2 h-max"
			on:click={() => handleSignOut(firebase, provider)}
		>
			Log Out
		</signOut>
	</nav>

	<User let:user let:auth>
		<slot decorator={FadeDecorator} />
		<signedOut slot="signed-out">
			<denied>ACCESS DENIED</denied>
			<signInButton
				on:mouseover={() => morph()}
				on:mouseout={() => unmorph()}
				role="button"
				id="enter-button"
				on:click={() => handleSignIn(firebase, provider)}
			>
				<span style="transform:translateY(-{($scale - 1) * 10}px)">
					<SvgFilter text="Secret Area" />
				</span>
			</signInButton>
		</signedOut>
	</User>
</FirebaseApp>

<style>denied{font-size:1.125rem;padding:.75rem 1rem;color:rgba(252,165,165,var(--tw-text-opacity));font-family:Quicksand}denied,signInButton{line-height:1.75rem;--tw-text-opacity:1;letter-spacing:.1em}signInButton{--tw-border-opacity:1;border-color:rgba(147,197,253,var(--tw-border-opacity));border-radius:.5rem;border-width:2px;display:flex;font-family:Nunito,sans-serif;font-size:1.25rem;line-height:1.5rem;margin:14rem auto;padding:1rem 2rem;color:rgba(31,41,55,var(--tw-text-opacity));width:-webkit-max-content;width:-moz-max-content;width:max-content}nav,signInButton{position:relative}nav{z-index:5}#enter-button{overflow:hidden;transition:box-shadow .5s;width:200px;height:60px;box-sizing:border-box;box-shadow:0 0 50px 0 rgba(147,197,253,.3),0 0 10px 0 rgba(147,197,253,.4),inset 0 0 10px 0 rgba(147,197,253,.3),inset 0 0 50px 0 rgba(147,197,253,.1)}#enter-button:hover{border-color:#060606;background:rgba(255,54,54,.8);box-shadow:0 0 50px 0 rgba(253,147,147,.3),0 0 10px 0 rgba(253,147,147,.4),inset 0 0 10px 0 rgba(253,228,147,.3),inset 0 0 50px 0 rgba(253,211,147,.1)}#enter-button:hover:after{border-radius:.5rem;font-family:Nunito,sans-serif;height:8rem;font-size:1.25rem;line-height:1.75rem;line-height:1.5rem;position:absolute;top:0;right:0;bottom:0;left:0;--tw-text-opacity:1;color:rgba(31,41,55,var(--tw-text-opacity));letter-spacing:.1em;width:200px;height:60px;box-sizing:border-box;content:""}</style>
