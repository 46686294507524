<script>
	import FadeDecorator from '@/components/FadeDecorator.svelte';
	import {goto} from '@roxi/routify';
	import {onMount} from 'svelte';
	let posts = [];
</script>

<main>
	<!-- App Bar -->
	<nav
		class="absolute top-0 flex w-full h-16 text-center text-gray-600 dark:text-white navbar"
	>
		<!-- Back Button -->
		<backbutton
			on:click={() => $goto('/admin')}
			class="absolute mr-auto text-4xl cursor-pointer left-3 top-14 w-max"
		>
			←
		</backbutton>
		<h1 class="flex mx-auto mt-2 w-max">BLOGGER</h1>
	</nav>
	<slot decorator={FadeDecorator} />
</main>

<style>nav{z-index:5}h1{font-weight:900;font-size:2.25rem;line-height:2.5rem;margin:auto;text-align:center}backbutton{opacity:.2;transition:opacity .2s}backbutton:hover{opacity:.8}main{min-height:120vh}</style>
